import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import SweetAlert from "react-bootstrap-sweetalert";
import { deleteCardTerminal, getAllRestaurants, getRestaurantCardTerminals } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import AddCardTerminal from "./add-terminal";
import { getUserRestaurantId } from "src/helpers/backend_helper";


const CardTerminals = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { restaurants, singleRestaurant } = useSelector( (state: any) => state.restaurant);
  const { items, loading } = useSelector( (state: any) => state.cardTerminals );
  const [currentItem, setCurrentItem] = useState<any>([]);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [drawer, setDrawer] = useState<Boolean>(false);
  const [selectedRestId, setSelRestId] = useState(localStorage.selectedRestaurantId || '');

  const { SearchBar } = Search;

  const pageOptions = {
    sizePerPage: 20,
    totalSize: items?.length, // replace later with size(companies),
    custom: true,    
  };

  const listColumns = [
    {
      dataField: "TerminalType",
      text: "Terminal type",
      sort: true,
    },
    {
      dataField: "IPAddress",
      text: "IP address",
      sort: true,
    },
    {
      dataField: "TerminalID",
      text: "TerminalID",
      sort: true,
    },
    {
      dataField: "PairingCode",
      text: "PairingCode",
      sort: true,
    },
    {
      dataField: "menu",
      editable: false,
      text: "Action",
      formatter: (cellContent: any, item: any) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => handleEditCardTerminal(item)}
            ></i>
          </Link>

          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => handleDeleteCardTerminal(item)}
            ></i>
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const userRestId = getUserRestaurantId()
    if (userRestId > 0) {
      dispatch(getRestaurantCardTerminals(userRestId));
    } else {
      if (getUserRestaurantId() <= 0) {
        dispatch(getAllRestaurants());
      }
    }
  }, []);

  useEffect(() => {
    if (selectedRestId !== '' && parseInt(selectedRestId) > 0) {
      localStorage.setItem("selectedRestaurantId", selectedRestId);
      dispatch(getRestaurantCardTerminals(selectedRestId))
    }
  }, [selectedRestId])

  const onRestaurantChange = (event: any) => {
    setSelRestId(event.target.value)
  }

  const handleEditCardTerminal = (item: any) => {
    history.push(`/cardTerminals/${item.id}`);
  };

  const handleDeleteCardTerminal = (item: any) => {
    setCurrentItem(item);
    setconfirm_alert(true);
  };

  const confirmDelete = () => {
    setconfirm_alert(false);
    dispatch(deleteCardTerminal(currentItem.id));
  };

  const handleAddNew = () => {
    setDrawer(true);
  };

  const onDrawerClose = (value: Boolean) => {
    setDrawer(value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Admins </title>
        </MetaTags>
        <Container fluid>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "80vh" }}
            >
              <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
            </div>
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={items}
                          columns={listColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <div className="row align-ite  ms-center">
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <h5 className="card-title">
                                        CardTerminal List{" "}
                                        <span className="text-muted fw-normal ms-2">
                                          ({items?.length})
                                        </span>
                                      </h5>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                     
                                        <div>
                                          <Link
                                            to="#"
                                            className="btn btn-light"
                                            onClick={handleAddNew}
                                          >
                                            <i className="bx bx-plus me-1"></i>{" "}
                                            Add New
                                          </Link>
                                        </div>
                                      
                                    </div>
                                  </div>
                                </div>
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon-search" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="4">
                                  {getUserRestaurantId() <= 0 && (
                                    <select name="restaurantId" onChange={onRestaurantChange} value={selectedRestId}>
                                      <option key="all" value="-1">Select restaurant</option>
                                      {restaurants.map((restaurant: any) => (
                                        <option key={restaurant.id} value={restaurant.id}>{restaurant.ShopName}</option>
                                      ))}
                                    </select>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {confirm_alert ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                confirmDelete();
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          ) : null}
          <AddCardTerminal open={drawer} onDrawerClose={onDrawerClose} selectedRestId={selectedRestId} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CardTerminals);
