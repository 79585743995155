import React, { useEffect, useMemo, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Row,  } from "reactstrap";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
//redux
import { useSelector, useDispatch } from "react-redux";
import { getAllRestaurants, getRestaurantById } from "../../store/actions";
import { getUserRestaurantId } from "src/helpers/backend_helper";
import { deleteBooking, getRestaurantBookings } from "src/store/bookings/actions";
import AddBooking from "./add-booking";
import SweetAlert from "react-bootstrap-sweetalert";


const Bookings = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { bookings, loading } = useSelector( (state: any) => state.bookings);
  const { restaurants, singleRestaurant } = useSelector( (state: any) => state.restaurant);
  const [selectedRestId, setSelRestId] = useState(localStorage.selectedRestaurantId || '');
  const [drawer, setDrawer] = useState<Boolean>(false);

  const [currentItem, setCurrentItem] = useState<any>([]);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const { SearchBar } = Search;


  const pageOptions = {
    sizePerPage: 20,
    totalSize: bookings.length, // replace later with size(companies),
    custom: true,
  };

  const restaurantType = useMemo(() => 
    ((getUserRestaurantId() > 0 ? singleRestaurant : restaurants.find((r: any) => r.id.toString() === selectedRestId))?.RestaurantType ?? 0),
  [selectedRestId, singleRestaurant, restaurants]);

  const bookingListColumns = useMemo(() => ([
    {
      dataField: "id",
      text: "Booking ID",
      sort: true,
    },
    {
      dataField: "BookingDateTime",
      text: "Booking DateTime",
      sort: true,
    },
    {
      dataField: "customer.First_Name",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "customer.Last_Name",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "customer.Telephone",
      text: "Telephone",
      sort: true,
    },
    {
      dataField: "customer.Mobile",
      text: "Mobile",
      sort: true,
    },
    {
      dataField: "customer.email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "table.Name",
      text: "Table",
      hidden: restaurantType === 1
    },
    {
      dataField: "employee.Title",
      text: "Employee",
      hidden: restaurantType === 0,
      formatter: (cellContent: any, item: any) => item.employee ? item.employee.First_Name + item.employee.Last_Name : ""
    },
    {
      dataField: "GuestCount",
      text: "Guest count",
      hidden: restaurantType === 1
    },
    {
      dataField: "menu_item_order",
      text: "Menu item order",
      hidden: restaurantType === 0
    },
    {
      dataField: "BookingStatus",
      text: "Status",
      sort: true,
    },
    {
      dataField: "menu",
      editable: false,
      text: "Action",
      formatter: (cellContent: any, item: any) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => handleEdit(item)}
            ></i>
          </Link>

          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => handleDelete(item)}
            ></i>
          </Link>
        </div>
      ),
    },
   
  ]) , [restaurantType]);

  useEffect(() => {
    const userRestId = getUserRestaurantId()
    if (userRestId > 0) {
      dispatch(getRestaurantBookings(userRestId));
      dispatch(getRestaurantById(userRestId));
    } else {
      if (userRestId <= 0) {
        dispatch(getAllRestaurants());
      }
    }
  }, [])

  useEffect(() => {
    localStorage.setItem("selectedRestaurantId", selectedRestId);
    if (selectedRestId) {
      dispatch(getRestaurantBookings(selectedRestId));
    }
  }, [selectedRestId]);

  const onRestaurantChange = (event: any) => {
    setSelRestId(event.target.value)
  }

  const handleAddNew = () => {
    setDrawer(true);
  };

  const onDrawerClose = (value: Boolean) => {
    setDrawer(!!value);
  };
  
  const handleEdit = (item: any) => {
    history.push(`/bookings/${item.id}`);
  };

  const handleDelete = (item: any) => {
    setCurrentItem(item);
    setconfirm_alert(true);
  };

  const confirmDelete = () => {
    setconfirm_alert(false);
    dispatch(deleteBooking(currentItem.id));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Bookings </title>
        </MetaTags>
        <Container fluid>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "80vh" }}
            >
              <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
            </div>
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={bookings}
                          columns={bookingListColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <div className="row align-ite  ms-center">
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <h5 className="card-title">
                                        Booking List{" "}
                                        <span className="text-muted fw-normal ms-2">
                                          ({bookings.length})
                                        </span>
                                      </h5>
                                    </div>
                                  </div>
                                  

                                  <div className="col-md-6">
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                     
                                        <div>
                                          <Link
                                            to="#"
                                            className="btn btn-light"
                                            onClick={handleAddNew}
                                          >
                                            <i className="bx bx-plus me-1"></i>{" "}
                                            Add New
                                          </Link>
                                        </div>                                      
                                    </div>
                                  </div>
                                </div>
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon-search" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="4">
                                  {getUserRestaurantId() <= 0 && (
                                    <select name="restaurantId" onChange={onRestaurantChange} value={selectedRestId}>
                                      <option key="all" value="-1">Select restaurant</option>
                                      {restaurants.map((restaurant: any) => (
                                        <option key={restaurant.id} value={restaurant.id}>{restaurant.ShopName}</option>
                                      ))}
                                    </select>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {confirm_alert ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                confirmDelete();
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          ) : null}
          <AddBooking open={drawer} onDrawerClose={onDrawerClose} selectedRestId={selectedRestId} restaurantType={restaurantType} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Bookings);
