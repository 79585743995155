import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Row,  } from "reactstrap";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
//redux
import { useSelector, useDispatch } from "react-redux";
import { deleteEmployee, getAllCustomers, getAllEmployees, getAllRestaurants, getRestaurantDrivers, getRestaurantEmployees, updateRestaurantById } from "../../store/actions";
import { getUserRestaurantId } from "src/helpers/backend_helper";
import AddEmployee from "./add-employee";
import SweetAlert from "react-bootstrap-sweetalert";

export const countyValues = ["Avon", "Bedfordshire", "Berkshire", "Borders", "Buckinghamshire", "Cambridgeshire", "Central", "Cheshire", "Cleveland", "Clwyd", "Cornwall", "CountyAntrim", "CountyArmagh", "CountyDown", "CountyFermanagh", "CountyLondonderry", "CountyTyrone", "Cumbria", "Derbyshire", "Devon", "Dorset", "DumfriesandGalloway", "Durham", "Dyfed", "EastSussex", "Essex", "Fife", "Gloucestershire", "Grampian", "GreaterManchester", "Gwent", "GwyneddCounty", "Hampshire", "Herefordshire", "Hertfordshire", "HighlandsandIslands", "Humberside", "IsleofWight", "Kent", "Lancashire", "Leicestershire", "Lincolnshire", "Lothian", "Merseyside", "MidGlamorgan", "Norfolk", "Northamptonshire", "Northumberland", "NorthYorkshire", "Nottinghamshire", "Oxfordshire", "Powys", "Rutland", "Shropshire", "Somerset", "SouthGlamorgan", "SouthYorkshire", "Staffordshire", "Strathclyde", "Suffolk", "Surrey", "Tayside", "TyneandWear", "Warwickshire", "West Glamorgan", "West Midlands", "WestSussex", "WestYorkshire", "Wiltshire", "Worcestershire"];
export const cityValues = ["Aberdeen", "Armagh", "Bangor", "Bath", "Belfast", "Birmingham", "Bradford", "Brighton", "Bristol", "Cambridge", "Canterbury", "Cardiff", "Carlisle", "Chester", "Chichester", "Coventry", "Derby", "Dundee", "Durham", "Edinburgh", "Ely", "Exeter", "Glasgow", "Gloucester", "Hereford", "Hull", "Inverness", "Lancaster", "Leeds", "Leicester", "Lichfield", "Lincoln", "Lisburn", "Liverpool", "London", "Londonderry", "Manchester", "Newcastle upon Tyne", "Newport", "Newry", "Norwich", "Nottingham", "Oxford", "Peterborough", "Plymouth", "Portsmouth", "Preston", "Ripon", "Salford", "Salisbury", "Sheffield", "Southampton", "St Albans", "St Davids", "Stirling", "Stoke-on-Trent", "Sunderland", "Swansea", "Truro", "Wakefield", "Wells", "Westminster", "Winchester", "Wolverhampton", "Worcester", "York"];

const Employee = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { employees, drivers, loading } = useSelector( (state: any) => state.employees);
  const { currentUser } = useSelector((state: any) => state.login);
  const [employeeList, setEmployeeList] = useState<any>([]);
  const [drawer, setDrawer] = useState<Boolean>(false);
  const { restaurants } = useSelector( (state: any) => state.restaurant);
  const [selectedRestId, setSelectedRestId] = useState(0);
  const [addDriver, setAddDriver] = useState(false);
  const [showDriver, setShowDriver] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState<any>(null);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [employeePublished, setEmployeeStatus] = useState(false);

  const { SearchBar } = Search;

  const pageOptions = {
    sizePerPage: 20,
    totalSize: employees.length, // replace later with size(companies),
    custom: true,
  };

  const employeeListColumns = [
    {
      dataField: "Title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "First_Name",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "Last_Name",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "Designation",
      text: "Designation",
      sort: true,
    },
    {
      dataField: "Email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "Street",
      text: "Address",
      sort: true,
    },
    {
      dataField: "City",
      text: "City",
      sort: true,
    },
    {
      dataField: "Postcode",
      text: "Postcode",
      sort: true,
    },
    {
      dataField: "Telephone",
      text: "Telephone",
      sort: true,
    },
    {
      dataField: "Mobile",
      text: "Mobile",
      sort: true,
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cellContent: any, employee: any) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => handleEditEmployee(employee)}
            ></i>
          </Link>

          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => handleDeleteEmployee(employee)}
            ></i>
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const userRestId = getUserRestaurantId();
    if (userRestId <= 0) {
      dispatch(getAllRestaurants());
    } else {
      dispatch(getRestaurantEmployees(userRestId));
    }
  }, []);

  useEffect(() => {
    if (selectedRestId > 0) {
      dispatch(getRestaurantEmployees("" + selectedRestId));
      dispatch(
        !showDriver ?
        getRestaurantEmployees("" + selectedRestId):
        getRestaurantDrivers("" + selectedRestId)
      );
    }
  }, [selectedRestId]);

  useEffect(() => {
    setEmployeeList(showDriver ? drivers : employees)
  }, [showDriver, employees, drivers]);

  const handleEditEmployee = (employee: any) => {
    history.push(`/employees/${employee.id}`);
  };

  const handleAddEmployee = () => {    
    setAddDriver(false)
    setDrawer(true)
  }

  const handleAddDriver = () => {
    setAddDriver(true)
    setDrawer(true)
  }

  const handleDeleteEmployee = (employee: any) => {
    setCurrentEmployee(employee);
    setConfirmAlert(true);
  }

  const handleConfirmDelete = () => {
    setConfirmAlert(false);
    if (currentEmployee) {
      dispatch(deleteEmployee(currentEmployee.id));
    }
  };

  const onRestaurantChange = (event: any) => {
    setSelectedRestId(event.target.value)
  }
  
  const onDrawerClose = () => {
    setDrawer(false);
    dispatch(
      !showDriver ?
      getRestaurantEmployees(getUserRestaurantId() > 0 ? getUserRestaurantId() : selectedRestId):
      getRestaurantDrivers(getUserRestaurantId() > 0 ? getUserRestaurantId() : selectedRestId)
    );
  }

  const onTypeChanged = (e: any) => {
    if (e.target.value !== "drivers") {
      setShowDriver(false);
      dispatch(getRestaurantEmployees(getUserRestaurantId() > 0 ? getUserRestaurantId() : selectedRestId));
    } else {
      setShowDriver(true);
      dispatch(getRestaurantDrivers(getUserRestaurantId() > 0 ? getUserRestaurantId() : selectedRestId));
    }
  }

  const handleMenuPublish = () => {
    const id = getUserRestaurantId() > 0 ? getUserRestaurantId() : selectedRestId
    dispatch(updateRestaurantById(id, {EmployeePublished: employeePublished ? 0 : 1}, null, getUserRestaurantId() <= 0))
    setEmployeeStatus(!employeePublished)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Admins </title>
        </MetaTags>
        <Container fluid>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "80vh" }}
            >
              <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
            </div>
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={employeeList}
                          columns={employeeListColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <div className="row align-ite  ms-center">
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <h5 className="card-title">
                                        {showDriver ? 'Driver' : 'Employee'} List{" "}
                                        <span className="text-muted fw-normal ms-2">
                                          ({employeeList.length})
                                        </span>
                                        <span className="fw-normal ms-2" style={{fontStyle: 'italic'}}>
                                        {employeePublished ? 'Published' : 'Draft' }
                                        </span>
                                      </h5>
                                    </div>
                                  </div>
                                  
                                  <div className="col-md-6">
                                    {(getUserRestaurantId() > 0 || selectedRestId > 0) && (
                                      <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                        {!showDriver && (
                                          <div>
                                            <Link
                                              to="#"
                                              className="btn btn-light"
                                              onClick={handleAddEmployee}
                                            >
                                              <i className="bx bx-plus me-1"></i>{" "}
                                              Add Employee
                                            </Link>
                                          </div>
                                        )}
                                        {showDriver && (
                                          <div>
                                            <Link
                                              to="#"
                                              className="btn btn-light"
                                              onClick={handleAddDriver}
                                            >
                                              <i className="bx bx-plus me-1"></i>{" "}
                                              Add Driver
                                            </Link>
                                          </div>
                                        )}
                                        <div>
                                          <Link
                                            to="#"
                                            className="btn btn-light"
                                            onClick={handleMenuPublish}
                                          >
                                            <i className="bx bx-check me-1"></i>{" "}
                                            {employeePublished ? 'Set as draft' : 'Publish'}
                                          </Link>
                                        </div>
                                      </div>
                                    )}
                                  </div>                           
                                </div>
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon-search" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="6" className="mt-2">
                                  {getUserRestaurantId() <= 0 && (
                                    <select name="restaurantId" onChange={onRestaurantChange} value={selectedRestId}>
                                      <option key="all" value="-1">Select restaurant</option>
                                      {restaurants.map((restaurant: any) => (
                                        <option key={restaurant.id} value={restaurant.id}>{restaurant.ShopName}</option>
                                      ))}
                                    </select>
                                  )}
                                  <select className="mx-4" onChange={onTypeChanged} value={showDriver ? "drivers" : "employees"}>
                                    <option value="employees">Show employees</option>
                                    <option value="drivers">Show drivers</option>
                                  </select>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {confirmAlert ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={handleConfirmDelete}
              onCancel={() => setConfirmAlert(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          ) : null}          
          <AddEmployee open={drawer} addDriver={addDriver} onDrawerClose={onDrawerClose} selectedRestId={selectedRestId} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Employee);
