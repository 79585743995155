import { OrderTypes } from "./actionTypes";

//get all dmin by sitecode
export const getAllOrders = (isWebOrder: boolean, offset: number, limit: number, dateStart: string, dateEnd: string) => ({
  type: OrderTypes. GET_ALL_ORDERS,
  payload: {
    isWebOrder,
    limit,
    offset,
    dateStart,
    dateEnd
  }
});

export const getAllOrdersSuccess = (orders: any) => ({
  type: OrderTypes.GET_ALL_ORDERS_SUCCESS,
  payload: orders,
});


// export const getAdminById = (id: string) => ({
//   type: OrderTypes.GET_ADMIN_BY_ID,
//   payload: id
// })

// export const getAdminByIdSuccess = (admin: any) => ({
//   type: CustomerTypes.GET_ADMIN_BY_ID_SUCCESS,
//   payload: admin,
// });

// export const getAdminByIdFail = (error: any) => ({
//   type: CustomerTypes.GET_ADMIN_BY_ID_FAIL,
//   payload: error,
// });







