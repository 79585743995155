import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Row,  } from "reactstrap";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
//redux
import { useSelector, useDispatch } from "react-redux";
import { getAllCustomers, getAllRestaurants } from "../../store/actions";
import { getUserRestaurantId } from "src/helpers/backend_helper";
import SendSMS from "./send-sms";


const CreateCampaign = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { customers, loading } = useSelector( (state: any) => state.customer);
  const { restaurants } = useSelector( (state: any) => state.restaurant);
  const { currentUser } = useSelector((state: any) => state.login);
  const [customerList, setCustomerList] = useState<any>([]);
  const [filter, setFilter] = useState({lastOrderDate: "", restaurantId: -1, notOrderSince: ""});  
  const [drawer, setDrawer] = useState<Boolean>(false);
  const [selected, setSelected] = useState<any[]>([])

  const { SearchBar } = Search;

  const pageOptions = {
    sizePerPage: 20,
    totalSize: customerList.length, // replace later with size(companies),
    custom: true,
  };

  const customerListtColumns = [
    {
      dataField: "id",
      text: "CustomerId",
      sort: true,
    },
    {
      dataField: "RestaurantName",
      text: "RestaurantName",
      sort: true,
      hidden: getUserRestaurantId() > 0
    },
    {
      dataField: "First_Name",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "Last_Name",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "Telephone",
      text: "Telephone",
      sort: true,
    },
    {
      dataField: "Mobile",
      text: "Mobile",
      sort: true,
    },
    {
      dataField: "Email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "Rating",
      text: "Rating",
      sort: true,
    },
    {
      dataField: "Member_Since",
      text: "Member Since",
      sort: true,
    },
    {
      dataField: "AmountSpent",
      text: "AmountSpent",
      sort: true,
    },
    {
      dataField: "NumberOfOrder",
      text: "NumberOfOrder",
      sort: true,
    },
    {
      dataField: "IsBlocked",
      text: "IsBlocked",
      sort: true,
    },
    {
      dataField: "Address",
      text: "Address",
      sort: true,
    },
    {
      dataField: "City",
      text: "City",
      sort: true,
    },
    {
      dataField: "Postcode",
      text: "Postcode",
      sort: true,
    },
    {
      dataField: "updatedAt",
      text: "updatedAt",
      sort: true,
    },
   
  ];

  useEffect(() => {
    dispatch(getAllCustomers());
    if (getUserRestaurantId() <= 0) {
      dispatch(getAllRestaurants());
    }
    setFilter({lastOrderDate: "", restaurantId: -1, notOrderSince: ""})
  }, []);

  useEffect(() => {
    setCustomerList(
      customers.filter((c: any) => 
        (filter.lastOrderDate === '' || new Date(c.updatedAt).getTime() >= new Date(filter.lastOrderDate).getTime())
        && (filter.restaurantId < 0 || c.restaurantId === filter.restaurantId)
        && (filter.notOrderSince === '' || new Date(c.updatedAt).getTime() < new Date(filter.notOrderSince).getTime())
      )
    )
  }, [customers, filter]);

  const handleEditAdmin = (admin: any) => {
    history.push(`/admins/${admin.id}`);
  };

  const onDateFilterChange = (event: any) => {
    const updatedFilter = Object.assign({...filter, [event.target.name]: event.target.value})
    setFilter(updatedFilter);
  }

  const onRestaurantChange = (event: any) => {
    const updatedFilter = Object.assign({...filter, restaurantId: event.target.value === '' ? -1 : parseInt(event.target.value)})
    setFilter(updatedFilter);
  }

  const handleSendMessage = () => {
    setDrawer(true)
  }
  
  const onDrawerClose = (value: Boolean) => {
    setDrawer(value);    
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Admins </title>
        </MetaTags>
        <Container fluid>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "80vh" }}
            >
              <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
            </div>
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={customerList}
                          columns={customerListtColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <div className="row align-item ms-center">
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      
                                      <Link to="/campaigns">
                                          <i className="fa fa-arrow-left me-1"></i>
                                        Campaigns
                                      </Link>
                                    </div>
                                  </div>
                                  
                                  <div className="col-md-6">
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                     
                                        <div>
                                          <button
                                            className="btn btn-light"
                                            onClick={handleSendMessage}
                                            disabled={(getUserRestaurantId() < 0 && filter.restaurantId < 0) || selected.length === 0}
                                          >
                                            <i className="bx bx-send me-1"></i>{" "}
                                            Create campaign ({selected.length})
                                          </button>
                                        </div>
                                      
                                    </div>
                                  </div>
                                </div>
                                
                                <div className="row align-item ms-center">
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon-search" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="8">
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                      <div className="d-flex">
                                        <div style={{marginRight: 6, marginTop: 3}}>Has order after:</div>
                                        <input type="date" name="lastOrderDate" onChange={onDateFilterChange} />
                                      </div>
                                      <div className="d-flex">
                                        <div style={{marginRight: 6, marginTop: 3}}>No order since:</div>
                                        <input type="date" name="notOrderSince" onChange={onDateFilterChange} />
                                      </div>
                                      {getUserRestaurantId() <= 0 && (
                                        <select name="restaurantId" onChange={onRestaurantChange}>
                                          <option key="all" value="-1">Select restaurant</option>
                                          {restaurants.map((restaurant: any) => (
                                            <option key={restaurant.id} value={restaurant.id}>{restaurant.ShopName}</option>
                                          ))}
                                        </select>
                                      )}
                                    </div>
                                  </Col>
                                </div>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                      selectRow={{
                                        mode: 'checkbox',
                                        onSelect: (row: any, isSelect: boolean, rowIndex: number, e: any) => {
                                          setSelected((sel: any) => isSelect ? [...sel, row.id] : sel.filter((item: any) => item !== row.id))
                                          return true
                                        },
                                        onSelectAll: (isSelect: boolean, rows: any, e: any) => {
                                          const ids = rows.map((r: any) => r.id)
                                          setSelected((sel: any) => isSelect ? ids : [])
                                          return isSelect ? ids : []
                                        }
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          
        <SendSMS
          open={drawer}
          onDrawerClose={onDrawerClose}
          customers={customerList.filter((c: any) => selected.indexOf(c.id) >= 0)}
          restaurantId={getUserRestaurantId() > 0 ? getUserRestaurantId() : filter.restaurantId}
        />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CreateCampaign);
