import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import paginationFactory, {PaginationListStandalone,PaginationProvider,} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import SweetAlert from "react-bootstrap-sweetalert";
import {deleteKDScreen, getAllRestaurants, getRestaurantById, getRestaurantKDScreens} from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import AddScreen from "./add-screen";
import { getUserRestaurantId } from "src/helpers/backend_helper";


const KitchenDisplayScreens = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { screens, loading } = useSelector( (state: any) => state.kitchenDisplayScreens);
  const { restaurants, singleRestaurant } = useSelector( (state: any) => state.restaurant);
  const [currentItem, setCurrentItem] = useState<any>([]);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [drawer, setDrawer] = useState<Boolean>(false);
  const [selectedRestId, setSelRestId] = useState(localStorage.selectedRestaurantId || '');

  useEffect(() => {
    const userRestId = getUserRestaurantId()
    if (userRestId > 0) {
      dispatch(getRestaurantById(userRestId));
      dispatch(getRestaurantKDScreens(userRestId))
    } else {
      if (getUserRestaurantId() <= 0) {
        dispatch(getAllRestaurants());
      }
    }
  }, []);

  useEffect(() => {
    if (selectedRestId !== '' && parseInt(selectedRestId) > 0) {
      dispatch(getRestaurantKDScreens(selectedRestId))
    }
  }, [selectedRestId])

  const { SearchBar } = Search;
  const pageOptions = {
    sizePerPage: 20,
    totalSize: screens.length, // replace later with size(companies),
    custom: true,
  };

  const screenListColumns = [
    {
      dataField: "id",
      text: "ID",
      sort: true
    },
    {
      dataField: "Name",
      text: "Name",
      sort: true
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cellContent: any, item: any) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => handleEdit(item)}
            ></i>
          </Link>

          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => handleDelete(item)}
            ></i>
          </Link>
        </div>
      ),
    },
  ];

  const onRestaurantChange = (event: any) => {
    setSelRestId(event.target.value)
  }

  const handleEdit = (restaurant: any) => {
    history.push(`/kitchenDisplayScreens/${restaurant.id}`);
  };

  const handleDelete = (admin: any) => {
    setCurrentItem(admin);
    setconfirm_alert(true);
  };

  const confirmDelete = () => {
    setconfirm_alert(false);
    dispatch(deleteKDScreen(currentItem.id));
  };

  const handleAdd = () => {
    setDrawer(true);
  };

  const onDrawerClose = (value: Boolean) => {
    setDrawer(false);
  };
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Kitchen Display Screens </title>
        </MetaTags>
        <Container fluid>
          
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={screens}
                          columns={screenListColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <div className="row align-ite  ms-center">
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <h5 className="card-title">
                                        Kitchen Display Screen List{" "}
                                        <span className="text-muted fw-normal ms-2">
                                          ({screens.length})
                                        </span>
                                      </h5>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                        <div>
                                          <Link
                                            to="#"
                                            className="btn btn-light"
                                            onClick={handleAdd}
                                          >
                                            <i className="bx bx-plus me-1"></i>{" "}
                                            Add New
                                          </Link>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon-search" />
                                    </div>
                                  </div>

                                  
                                </Col>
                                <Col sm="4">
                                  {getUserRestaurantId() <= 0 && (
                                    <select name="restaurantId" onChange={onRestaurantChange} value={selectedRestId}>
                                      <option key="all" value="-1">Select restaurant</option>
                                      {restaurants.map((restaurant: any) => (
                                        <option key={restaurant.id} value={restaurant.id}>{restaurant.ShopName}</option>
                                      ))}
                                    </select>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                  {loading ? (
                                    <div
                                      className="d-flex justify-content-center align-items-center"
                                      style={{ height: "20vh" }}
                                    >
                                      <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
                                    </div>
                                  ) : (
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                    />
                                  )}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          {confirm_alert ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                confirmDelete();
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          ) : null}
          <AddScreen 
            open={drawer} onDrawerClose={onDrawerClose} 
            selectedRestId={selectedRestId}
          />   
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(KitchenDisplayScreens);
