import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Row,  } from "reactstrap";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
//redux
import { useSelector, useDispatch } from "react-redux";
import { getAllOrders, getRestaurantById } from "../../store/actions";
import { getCustomerByIdAsync, getUserRestaurantId } from "../../helpers/backend_helper";
import axios from "axios";
import SendSMS from "./send-sms";
import OrderDetail from "./order-detail";
import { convertSecondsToHMS } from "src/helpers/general";


const Order = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orders, totalCount, loading } = useSelector( (state: any) => state.order);
  const { singleRestaurant: restaurant } = useSelector( (state: any) => state.restaurant);
  const { currentUser } = useSelector((state: any) => state.login);
  const [orderList, setOrderList] = useState<any>([]);
  const [drawer, setDrawer] = useState<Boolean>(false);
  const [customerId, setCustomerId] = useState<string>("");
  const [restaurantId, setRestaurantId] = useState<number>(-1);
  const [isWebOrderOnly, setWebOrderOnly] = useState(false);
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [showDetail, setShowDetail] = useState<Boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [isHospitality, setHospitality] =  useState(true);

  const { SearchBar } = Search;

  console.log('orders =>', orders)

  const sidePerPage = 20;
  const pageOptions = {
    sizePerPage: sidePerPage,
    totalSize: totalCount, // replace later with size(companies),
    custom: true,
    page: getUserRestaurantId() < 0 ? currentPage : undefined,
    onPageChange: (pageNumber: number, sizePerPage: number) => {
      if (getUserRestaurantId() < 0) {
        setCurrentPage(pageNumber);     
        dispatch(getAllOrders(isWebOrderOnly, (pageNumber-1) * sizePerPage, sizePerPage, dateStart, dateEnd));
      }
    }
  };

  const ordersListtColumns = [
    {
      dataField: "OrderNo",
      text: "Order No",
      sort: true,
    },
    {
      dataField: "RestaurantName",
      text: "Restaurant",
      sort: true,
      hidden: getUserRestaurantId() > 0
    },
    {
      dataField: "OrderDate",
      text: "Date",
      sort: true,
    },
    {
      dataField: "Order_Type",
      text: "Order Type",
      sort: true,
      hidden: !isHospitality,
    },
    {
      dataField: "Order_Status",
      text: "Order Status",
      sort: true,
    },
    {
      dataField: "CustomerId",
      text: "CustomerId",
      sort: true,
      hidden: !isHospitality,
      formatter: (cell: any, row: any) => (
        <a href="#" onClick={() => sendSMS(cell, row)}>
          <span>{cell} {parseInt(cell)>0}</span>
        </a>
      )
    },
    {
      dataField: "PayStatus",
      text: "Pay Status",
      sort: true,
    },
    {
      dataField: "Payment_Type",
      text: "Payment Type ",
      sort: true,
    },
    {
      dataField: "TotalAmount",
      text: "Total",
      sort: true,
    },
    {
      dataField: "PrepTimeInSec",
      text: "Prep. Time",
      sort: true,
      hidden: !isHospitality,
      formatter: (cell: any, row: any) => convertSecondsToHMS(cell)
    },
    {
      dataField: "ServiceCharge",
      text: "Service Charge",
      sort: true,
      hidden: !isHospitality,
    },
    {
      dataField: "DeliveryCharge",
      text: "Delivery Charge",
      sort: true,
    },
    {
      dataField: "Discount",
      text: "Discount",
      sort: true,
    },
    {
      dataField: "WebOrderId",
      text: "WebOrderId",
      sort: true,
      hidden: !isHospitality,
    },
    {
      dataField: "Operator",
      text: "Operator",
      sort: true,
    },
    {
      dataField: "ComputerName",
      text: "Computer Name",
      sort: true,
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cellContent: any, row: any) => (
        <div className="d-flex gap-3">
          <button className="btn btn-success" onClick={() => handleRowClick(row)}>
            View
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (getUserRestaurantId() > 0) {
      dispatch(getRestaurantById(getUserRestaurantId()));
    }
  }, []);

  useEffect(() => {
    if (getUserRestaurantId() > 0 && restaurant && restaurant.id > 0) {
      setHospitality(restaurant.RestaurantType === 0);
    }
  }, [restaurant])

  useEffect(() => {
    dispatch(getAllOrders(isWebOrderOnly, 0, sidePerPage, dateStart, dateEnd));
  }, [isWebOrderOnly]);

  useEffect(() => {
    if (getUserRestaurantId() > 0){
      // frontend filtering
      setOrderList(orders.filter((order: any) => 
        (!dateStart || new Date(order.OrderDate).getTime() >= new Date(dateStart).getTime()) && 
        (!dateEnd || new Date(order.OrderDate).getTime() <= new Date(dateEnd).getTime()))
      )
    } else {
      // backend filtering
      setOrderList(orders.filter((order: any) => `${order.OrderNo} ${order.CustomerId} ${order.TotalAmount} ${order.RestaurantName} ${order.Order_Status} ${order.Order_Type} ${order.ComputerName} ${order.PayStatus} ${order.Payment_Type}`.toLowerCase().includes(searchText.toLowerCase())))
    }
  }, [orders, dateStart, dateEnd, searchText]);

  const handleEditAdmin = (admin: any) => {
    history.push(`/admins/${admin.id}`);
  };

  const sendSMS = (customerId: any, rowContent: any) => {
    setCustomerId(customerId);
    setRestaurantId(rowContent.RestaurantId)
    setDrawer(true);
  }
  
  const onDrawerClose = (value: Boolean) => {
    setDrawer(value);    
  };

  const rowStyleFunc = (row: any, index: number) => {
    if (row["IsYummyOrder"] === true) {
      return { backgroundColor: '#FFB6C1' }
    } else if (row["IsWooOrder"] === true) {
      return { backgroundColor: '#ADD8E6' }
    } else if (row["IsSnappyOrder"] === true) {
      return { backgroundColor: '#FFCCCB' }
    }
    return {}
  }
  
  const handleWebOrderOnly = (e: any) => {
    setWebOrderOnly(e.target.checked);
  }

  const onDateFilterChange = (e: any) => {
    let startDate = dateStart, endDate = dateEnd;
    if (e.target.name === 'dateEnd') {
      setDateEnd(e.target.value);
      endDate = e.target.value;
    } else if (e.target.name === 'dateStart') {
      setDateStart(e.target.value);
      startDate = e.target.value;
    }
    setCurrentPage(0)
    dispatch(getAllOrders(isWebOrderOnly, 0, sidePerPage, startDate, endDate));
  }

  const handleRowClick = (row: any) => {
    setSelectedOrder(row);
    setShowDetail(true);
  }

  const onTableChange = (type: string, params: any) => {
    if (type === 'search') {
      setSearchText(params.searchText);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Admins </title>
        </MetaTags>
        <Container fluid>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "80vh" }}
            >
              <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
            </div>
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={orderList}
                          columns={ordersListtColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <div className="row align-item  ms-center">
                                  <div className="col-md-3">
                                    <div className="mb-3">
                                      <h5 className="card-title">
                                      orders List{" "}
                                        <span className="text-muted fw-normal ms-2">
                                          ({orders.length})
                                        </span>
                                      </h5>
                                    </div>
                                  </div>                             
                                </div>
                                <div className="d-flex flex-row col-md-8">
                                  <div className="mt-3">
                                    <input type="checkbox" name="IsWebOrder" checked={isWebOrderOnly} onChange={(e) => handleWebOrderOnly(e)} />
                                    <label htmlFor="IsWebOrder" className="ml-2">&nbsp; Web orders only</label>
                                  </div>                                  
                                  <div className="mt-3">
                                    <label htmlFor="dateStart">&nbsp;&nbsp;  Filter by date from: &nbsp; </label>
                                    <input type="date" name="dateStart" value={dateStart} onChange={onDateFilterChange} />
                                  </div>
                                  <div className="mt-3">
                                    <label htmlFor="dateEnd">&nbsp;  to: &nbsp; </label>
                                    <input type="date" name="dateEnd" value={dateEnd} onChange={onDateFilterChange} />
                                  </div>
                                </div>
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon-search" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      remote={getUserRestaurantId() < 0}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      bordered={false}
                                      striped={false}
                                      rowStyle={rowStyleFunc}
                                      onTableChange={onTableChange}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <SendSMS open={drawer} onDrawerClose={onDrawerClose} customerId={customerId} restaurantId={restaurantId} />
          <OrderDetail open={showDetail} onDrawerClose={() => setShowDetail(false)} order={selectedOrder} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Order);
