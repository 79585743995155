import { StatsTypes } from "./actionTypes";

//get all stats
export const getAllStats = () => ({
  type: StatsTypes.GET_ALL_STATS,
});

export const getAllStatsSuccess = (stats: any) => ({
    type: StatsTypes.GET_ALL_STATS_SUCCESS,
    payload: stats
});