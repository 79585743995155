import { RestaurantTypes } from "./actionTypes";

//get all dmin by sitecode
export const getAllRestaurants = () => ({
  type: RestaurantTypes. GET_ALL_RESTAURANTS,
});

export const getAllRestaurantSuccess = (restaurants: any) => ({
  type: RestaurantTypes.GET_ALL_RESTAURANTS_SUCCESS,
  payload: restaurants,
});


export const getRestaurantById = (id: string) => ({
  type: RestaurantTypes.GET_RESTAURANT_BY_ID,
  payload: id
})

export const getRestaurantByIdSuccess = (restaurant: any) => ({
  type: RestaurantTypes.GET_RESTAURANT_BY_ID_SUCCESS,
  payload: restaurant,
});

export const updateRestaurantById = (id: string, restaurant: any, history: any, fromAdmin: boolean) => ({
  type: RestaurantTypes.UPDATE_RESTAURANT_BY_DOCID,
  payload: {id, restaurant, history, fromAdmin}
})

export const updateRestaurantByIdSuccess = (restaurant: any, fromAdmin: boolean) => ({
  type: RestaurantTypes.UPDATE_RESTAURANT_BY_DOCID_SUCCESS,
  payload: {
    restaurant,
    fromAdmin
  },
});


export const storeRestaurant = (restaurant: any) => ({
  type: RestaurantTypes.ADD_RESTAURANT,
  payload: restaurant,
});

export const storeRestaurantSuccess = (restaurant: any) => ({
  type: RestaurantTypes.ADD_RESTAURANT_SUCCESS,
  payload: restaurant,
});





