import React, { useEffect, useMemo, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, useParams, Link, useHistory } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from "react-redux";
import { getMenuCategoryById, getRestaurantById, getRestaurantKDScreens, getRestaurantMenuCategories, getRestaurantPrinters, updateMenuCategoryById, updateRestaurantById } from "../../store/actions";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import Icon from "@ailibs/feather-react-ts";
import { SpinnerCircular } from "spinners-react";
import { hexColorToInt, intColorToHex } from "src/helpers/general";
import ImageGallery from "./image-gallery";
import { getMenuImageUrl } from ".";


const EditMenuCategory = (props: any) => {
  const dispatch = useDispatch();
  const { id } = useParams<any>();
  const history = useHistory();

  const { singleCategory, loading, categories } = useSelector((state: any) => state.menu);
  const { screens } = useSelector((state: any) => state.kitchenDisplayScreens);
  const { items: printers } = useSelector((state: any) => state.printers);
  const [categoryType, setCategoryType] = useState(0);
  const [linkedCategories, setLinkedCategories] = useState<any>([]);
  const [backImage, setBackImage] = useState<any>(null);
  const backImageRef = useRef<any>(null);
  const [showImageGallery, setShowImageGallery] = useState(false);
  const [printer, setPrinter] = useState(0);

  useEffect(() => {
    dispatch(getMenuCategoryById(id));
  }, []);

  useEffect(() => {
    if (singleCategory) {
      if (singleCategory.RestaurantId) {
        dispatch(getRestaurantMenuCategories(singleCategory.RestaurantId));
        dispatch(getRestaurantKDScreens(singleCategory.RestaurantId));
        dispatch(getRestaurantPrinters(singleCategory.RestaurantId));
      }
      setCategoryType(singleCategory.CategoryType);
      if (singleCategory.LinkedCategoryId) {
        setLinkedCategories(singleCategory.LinkedCategoryId.split(",").map((s: string) => parseInt(s)))
      } else {
        setLinkedCategories([])
      }
      if (singleCategory.BackImage) {
        setBackImage(singleCategory.BackImage);
      } else {
        setBackImage(null);
      }
      setPrinter(singleCategory.Printer_Id)
    } else {
      setBackImage(null);
    }
  }, [singleCategory]);

  const linkingCategories = useMemo(() => {
    return categories.filter((c: any) => !!c.CategoryType && c.CategoryType > 0 && c.CategoryType < 5)
  }, [categories]);

  const handleEditCategory = (values: any) => {
    history.push("/menu/categories");
    dispatch(updateMenuCategoryById(id, {
      ...values,      
      ForeColor: hexColorToInt(values['ForeColor']),
      BackColor: hexColorToInt(values['BackColor']),
      BaseColor: hexColorToInt(values['BaseColor']),
      CategoryType: values.CategoryType ? parseInt(values.CategoryType) : 0,
      LinkedCategoryId: linkedCategories.join(","),
      LinkedKitchenDisplayScreenId: values.LinkedKitchenDisplayScreenId ? parseInt(values.LinkedKitchenDisplayScreenId) : 0,
      BackImage: backImage,
      RestaurantId: singleCategory.RestaurantId,
      Printer_Id: printer
    }, history));
  };

  const validateToppingGid = (value: any, ctx: any, input: number) => {
    const ids = categories.filter((c: any) => !!c.ToppingGroupId && c.id != id).map((c: any) => c.ToppingGroupId);
    if (value > 0 && ids.indexOf(parseInt(value.toString())) >= 0) {
      return false;
    }
    return true;
  }

  const onTypeChange = (e: any) => {
    setCategoryType(parseInt(e.target.value));
  }

  const onPrinterChange = (e: any) => {
    setPrinter(parseInt(e.target.value));
  }

  const onLinkedCategoryChangeAll = (value: any) => {
    setLinkedCategories(value ? linkingCategories.map((cat: any) => cat.id) : []);
  }

  const onLinkedCategoryChange = (id: any) => {
    setLinkedCategories((ids: any) => ids.indexOf(id) >= 0 ? ids.filter((id1: any) => id1 != id) : [...ids, id]);
  }

  const onBackImageChange = (e: any) => {
    let reader = new FileReader();
    reader.onload = function(e1: any) {
      setBackImage(e1.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>          
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "20vh" }}
              >
                <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
              </div>
            ) : (
              <AvForm
                onValidSubmit={(e: any, values: any) => {
                  handleEditCategory(values);
                }}
              >
                <Row className="align-items-center">
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="Name"
                        label="Name"
                        type="text"
                        errorMessage="Name required"
                        validate={{
                          required: { value: true },
                        }}
                        value={singleCategory?.Name || ""}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3 align-items-end">
                      <img src={getMenuImageUrl(backImage)} width={100} height={100}/>
                      <span className="btn btn-light mx-2 cursor-pointer" onClick={() => setShowImageGallery(true)}>Select from gallery</span>
                      <AvField
                        id="MenuCategoryBackImage"
                        name="BackImage"
                        label="Background image"
                        type="file"
                        style={{display: "none"}}
                        onChange={onBackImageChange}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="Position"
                        label="Position"
                        type="number"
                        errorMessage="Invalid position"
                        validate={{
                          required: { value: true },
                        }}
                        value={singleCategory?.Position}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="PrintPosition"
                        label="Print position"
                        type="number"
                        errorMessage="Invalid print position"
                        validate={{
                          required: { value: true },
                        }}
                        value={singleCategory?.PrintPosition}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="ForeColor"
                        label="Foreground color"
                        type="color"
                        errorMessage="Invalid forground color"
                        validate={{
                          required: { value: false },
                        }}
                        value={intColorToHex(singleCategory?.ForeColor) || "#000000"}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="BackColor"
                        label="Background color"
                        type="color"
                        errorMessage="Invalid background color"
                        validate={{
                          required: { value: true },
                        }}
                        value={intColorToHex(singleCategory?.BackColor) || "#000000"}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="BaseColor"
                        label="Base color"
                        type="color"
                        errorMessage="Invalid BaseColor"
                        validate={{
                          required: { value: false },
                        }}
                        value={intColorToHex(singleCategory?.BaseColor) || "#000000"}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="FontSize"
                        label="Font size"
                        type="number"
                        errorMessage="Invalid font size"
                        validate={{
                          required: { value: false },
                        }}
                        value={singleCategory?.FontSize || 20}
                      />
                    </div>
                  </Col>
                </Row>
                
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="LineAfterCat"
                        label="LineAfterCat"
                        type="checkbox"
                        errorMessage="Invalid LineAfterCat"
                        value={!!singleCategory?.LineAfterCat}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="ForWetItems"
                        label="ForWetItems"
                        type="checkbox"
                        errorMessage="Invalid ForWetItems"
                        value={!!singleCategory?.ForWetItems}
                      />
                    </div>
                  </Col>
                </Row>              

                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="ForDryItems"
                        label="ForDryItems"
                        type="checkbox"
                        errorMessage="Invalid ForDryItems"
                        value={!!singleCategory?.ForDryItems}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="AddQuantityToLineItem"
                        label="AddQuantityToLineItem"
                        type="checkbox"
                        errorMessage="Invalid AddQuantityToLineItem"
                        value={!!singleCategory?.AddQuantityToLineItem}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="d-flex flex-column">
                      <label>Linked categories</label>
                      <div className="d-flex justify-content-between w-[100%]">
                        <span>Select/unselect all</span>
                        <input type="checkbox" onChange={(e) => onLinkedCategoryChangeAll(e.target.checked)} checked={linkingCategories.reduce((prev: boolean, item: any) => linkedCategories.indexOf(item.id) >= 0 && prev, true)} />
                      </div>
                      {linkingCategories.map((cat: any) => (
                        <div key={cat.id} className="d-flex justify-content-between w-[100%]">
                          <span>{cat.Name}</span>
                          <input type="checkbox" checked={linkedCategories.indexOf(cat.id) >= 0} onChange={() => onLinkedCategoryChange(cat.id)} />
                        </div>
                      ))}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="ExcludeFromDiscount"
                        label="ExcludeFromDiscount"
                        type="checkbox"
                        errorMessage="Invalid ExcludeFromDiscount"
                        value={!!singleCategory?.ExcludeFromDiscount}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3 align-items-end">
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="LinkedKitchenDisplayScreenId"
                        label="Linked kitchen display screen"
                        placeholder="LinkedKitchenDisplayScreenId"
                        type="select"
                        value={singleCategory?.LinkedKitchenDisplayScreenId || 0}
                        onChange={onTypeChange}
                      >
                        <option value={0}>None</option>
                        {screens.map((screen: any) => (
                          <option key={screen.id} value={screen.id}>{screen.Name}</option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="DisableLabelPrinting"
                        label="Disable label printing"
                        type="checkbox"
                        value={singleCategory?.DisableLabelPrinting || false}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="CategoryType"
                        label="Type"
                        placeholder="Type"
                        type="select"
                        value={singleCategory?.CategoryType || 0}
                        onChange={onTypeChange}
                      >
                        <option value={0}>Standard</option>
                        <option value={1}>Toppings</option>
                        <option value={2}>Dressings</option>
                        <option value={3}>Special Instructions</option>
                        <option value={4}>Pop Up</option>
                        <option value={5}>Miscellaneous</option>
                      </AvField>
                    </div>
                  </Col>
                  {categoryType === 1 && (                    
                    <Col md="6">
                      <div className="mb-3">
                        <AvField
                          name="ToppingGroupId"
                          label="ToppingGroupId"
                          type="number"
                          defaultValue={0}
                          errorMessage="Topping group id already exists"
                          validate={{
                            validateToppingGid,
                            max: {value: 5, errorMessage: "max is 5"},
                            min: {value: 0, errorMessage: "min is 0"}
                          }}
                          value={singleCategory?.ToppingGroupId || 1}
                        />
                      </div>
                    </Col>
                  )}
                </Row>
                <Row>                  
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="PrinterId"
                        label="Printer"
                        type="select"
                        value={printer}
                        onChange={onPrinterChange}
                      >
                        <option value={0}>None</option>
                        {printers.map((p: any) => (
                          <option key={p.id} value={p.id}>{p.designation}</option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                </Row>

                <Row style={{alignItems: 'flex-end'}}>
                  <Col>
                    <div className="text-end">
                      <button type="submit" className="btn btn-success save-user update-btn" disabled={loading}>
                        {loading ? (
                          <SpinnerCircular
                            size={30}
                            thickness={150}
                            speed={150}
                            color="#36ad47"
                            secondaryColor="rgba(0, 0, 0, 0.44)"
                          />
                        ) : (
                          <>Update</>
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            )}
            
            <ImageGallery 
              restaurantId={singleCategory?.RestaurantId} 
              open={showImageGallery} 
              onDrawerClose={() => setShowImageGallery(false)} 
              onSelect={(id: any) => {
                setBackImage("id:" + id);
                setShowImageGallery(false);
              }}
            />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditMenuCategory);
