import { call, put, takeEvery } from "redux-saga/effects";
// Crypto Redux States
import { OrderTypes } from "./actionTypes";
import { IResponse } from "../../interfaces/general";
import {getAllOrdersSuccess} from "./actions";

import { getAllOrdersAsync, getRestaurantOrdersAsync, getUserRestaurantId } from "../../helpers/backend_helper";

function* getAllOrders({ payload: {isWebOrder, offset, limit, dateStart, dateEnd}}: any) {
  try {
    const restaurantId = getUserRestaurantId()
    let response: IResponse;
    if (restaurantId > 0) {
      response = yield call(getRestaurantOrdersAsync, restaurantId, isWebOrder);
    } else {
      response = yield call(getAllOrdersAsync, isWebOrder, offset, limit, dateStart, dateEnd);
    }
    console.log('response =>', response)
    if(response.success) {
      yield put(getAllOrdersSuccess({
        orders: (restaurantId > 0 ? response.result : response.result.orders).sort((a:any, b:any) => b.id-a.id),
        totalCount: restaurantId > 0 ? response.result.length : response.result.totalCount
      }));
    } 
  } catch (error) {
    console.log(error)
  }
}

// function* getAdminById({ payload: id}: any) {
//   try {
//     const response: IResponse = yield call(getAdminByIdAsync, id);
//     if(response.success) {
//       yield put(getAdminByIdSuccess(response.result));
//     }
//   } catch (error) {
//     yield put(getAdminByIdFail(error));
//   }
// }




function* orderSaga() {
  yield takeEvery(OrderTypes.GET_ALL_ORDERS, getAllOrders)
  // yield takeEvery(AdminTypes.GET_ADMIN_BY_ID, getAdminById)
}

export default orderSaga;


