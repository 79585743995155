import { CustomerTypes } from "./actionTypes";

//get all dmin by sitecode
export const getAllCustomers = () => ({
  type: CustomerTypes. GET_ALL_CUSTOMERS,
});

export const getAllCustomersSuccess = (customers: any) => ({
  type: CustomerTypes.GET_ALL_CUSTOMERS_SUCCESS,
  payload: customers,
});

export const getCustomerById = (customerId: string) => ({
  type: CustomerTypes.GET_CUSTOMER_BY_ID,
  payload: customerId
});

export const getCustomerByIdSuccess = (customer: any) => ({
  type: CustomerTypes.GET_CUSTOMER_BY_ID_SUCCESS,
  payload: customer,
});

// export const getAdminById = (id: string) => ({
//   type: CustomerTypes.GET_ADMIN_BY_ID,
//   payload: id
// })

// export const getAdminByIdSuccess = (admin: any) => ({
//   type: CustomerTypes.GET_ADMIN_BY_ID_SUCCESS,
//   payload: admin,
// });

// export const getAdminByIdFail = (error: any) => ({
//   type: CustomerTypes.GET_ADMIN_BY_ID_FAIL,
//   payload: error,
// });







